<template>
	<div id="template-page">
		<div class="page_controls">
			<div>
				<div class="templateTitre">MODULE PAGE</div>
				<div>
					Nom de la page
					<el-input :min="1" :minlength="1" placeholder="page titre" @input="onInputChange" v-model="currentNode.title" clearable></el-input>
				</div>
			</div>
			<el-popconfirm @confirm="removePage" confirm-button-text='OK' cancel-button-text='Annuler' icon="el-icon-info" icon-color="red" title="Êtes-vous sûr ?">
				<el-button type="button" class="btn red" slot="reference">Supprimer cette page</el-button>
			</el-popconfirm>
		</div>

		<div id="buttons-template-page">
			<span>Ajouter un contenu à cette page :</span>
			<div id="container-moduleSelection">
				<div id="modules-titres">
					<div class="titreChoixModules">
						<img src="../assets/title.svg" alt="titre icone" />
						<h4>Images & textes</h4>
					</div>
					<div class="choixModules">

						<div class="popover__wrapper">
							<button @click="addModule('BandeauImageModule')" type="button" class="btn blue editionAjouter">Bandeau image & titre</button>
							<div class="popover__content">
								<p class="popover__message">Module de titre blabla</p>
								<img alt="Joseph Francis Joey Tribbiani, Jr." src="../assets/title.svg" />
							</div>
						</div>
						<div class="popover__wrapper">
							<button @click="addModule('CarouselModule')" type="button" class="btn blue editionAjouter">Carousel d'accueil</button>
							<div class="popover__content">
								<p class="popover__message">Module d'accueil blabla</p>
								<img alt="Joseph Francis Joey Tribbiani, Jr." src="../assets/title.svg" />
							</div>
						</div>
						<div class="popover__wrapper">
							<button @click="addModule('ImageParagraphModule')" type="button" class="btn blue editionAjouter">Image & paragraphe</button>
							<div class="popover__content">
								<p class="popover__message">Module d'image avec paragraphe'</p>
								<img alt="Joseph Francis Joey Tribbiani, Jr." src="../assets/layout.svg" />
							</div>
						</div>
					</div>
				</div>
				<div id="modules-textes">
					<div class="titreChoixModules">
						<img src="../assets/layout.svg" alt="textes icone" />
						<h4>Textes</h4>
					</div>
					<div class="choixModules">
						<div class="popover__wrapper">
							<button @click="addModule('TitleModule')" type="button" class="btn blue editionAjouter">Titre</button>
							<div class="popover__content">
								<p class="popover__message">Module de titre principal</p>
								<img alt="Joseph Francis Joey Tribbiani, Jr." src="../assets/layout.svg" />
							</div>
						</div>
						<div class="popover__wrapper">
							<button @click="addModule('TextareaModule')" type="button" class="btn blue editionAjouter">Paragraphe une colonne</button>
							<div class="popover__content">
								<p class="popover__message">Module de paragraphe</p>
								<img alt="Joseph Francis Joey Tribbiani, Jr." src="../assets/layout.svg" />
							</div>
						</div>
						<div class="popover__wrapper">
							<button @click="addModule('DoubleTextareaModule')" type="button" class="btn blue editionAjouter">Paragraphe deux colonnes</button>
							<div class="popover__content">
								<p class="popover__message">Module de paragraphe double colonne</p>
								<img alt="Joseph Francis Joey Tribbiani, Jr." src="../assets/layout.svg" />
							</div>
						</div>
					</div>
				</div>
				<div id="modules-medias">
					<div class="titreChoixModules">
						<img src="../assets/sound-frecuency.svg" alt="médias icone" />
						<h4>Médias</h4>
					</div>
					<div class="choixModules">
						<div class="popover__wrapper">
							<button @click="addModule('ImageModule')" type="button" class="btn blue editionAjouter">Une image</button>

							<div class="popover__content">
								<p class="popover__message">Module d'une image</p>
								<img alt="Joseph Francis Joey Tribbiani, Jr." src="../assets/layout.svg" />
							</div>
						</div>
						<div class="popover__wrapper">
							<button @click="addModule('DoubleImageModule')" type="button" class="btn blue editionAjouter">Deux images</button>
							<div class="popover__content">
								<p class="popover__message">Module de deux images</p>
								<img alt="Joseph Francis Joey Tribbiani, Jr." src="../assets/layout.svg" />
							</div>
						</div>
						<div class="popover__wrapper">
							<button @click="addModule('VideoModule')" type="button" class="btn blue editionAjouter">Vidéo</button>
							<div class="popover__content">
								<p class="popover__message">Module vidéo</p>
								<img alt="Joseph Francis Joey Tribbiani, Jr." src="../assets/layout.svg" />
							</div>
						</div>
						<div class="popover__wrapper">
							<button @click="addModule('AudioModule')" type="button" class="btn blue editionAjouter">Audio</button>
							<div class="popover__content">
								<p class="popover__message">Module de titre principal</p>
								<img alt="Joseph Francis Joey Tribbiani, Jr." src="../assets/layout.svg" />
							</div>
						</div>
						<div class="popover__wrapper">
							<button @click="addModule('GalerieModule')" type="button" class="btn blue editionAjouter">Galerie</button>
							<div class="popover__content">
								<p class="popover__message">Module de titre principal</p>
								<img alt="Joseph Francis Joey Tribbiani, Jr." src="../assets/layout.svg" />
							</div>
						</div>
						<div class="popover__wrapper">
							<button @click="addModule('YoutubeModule')" type="button" class="btn blue editionAjouter">Youtube</button>
							<div class="popover__content">
								<p class="popover__message">Module Youtube</p>
								<img alt="Joseph Francis Joey Tribbiani, Jr." src="../assets/layout.svg" />
							</div>
						</div>
					</div>
				</div>
				<div id="modules-interactions">
					<div class="titreChoixModules">
						<img src="../assets/interactivity.svg" alt="interaction icone" />
						<h4>Interactions</h4>
					</div>
					<div class="choixModules">
						<div class="popover__wrapper">
							<button @click="addModule('ButtonImageModule')" type="button" class="btn blue editionAjouter">Trois boutons liens avec images</button>
							<div class="popover__content">
								<p class="popover__message">Module de trois boutons avec images</p>
								<img alt="Joseph Francis Joey Tribbiani, Jr." src="../assets/layout.svg" />
							</div>
						</div>
						<div class="popover__wrapper">
							<button @click="addModule('ButtonModule')" type="button" class="btn blue editionAjouter">Bouton lien</button>
							<div class="popover__content">
								<p class="popover__message">Module d'un bouton</p>
								<img alt="Joseph Francis Joey Tribbiani, Jr." src="../assets/layout.svg" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Input, Button, Popconfirm } from 'element-ui';

export default {
	name: 'AjouterModule',
	props: ['currentNode'],
	components: {
		'el-input': Input,
		'el-button': Button,
		'el-popconfirm': Popconfirm,
	},
	methods: {
		addModule: function (value) {
			this.$emit('addModule', value);
		},
		onInputChange: function () {
			this.currentNode.data.sortedName = this.currentNode.title.normalize('NFD').toLowerCase().replace(/ /g, '_').replace(/[\u0300-\u036f]/g, "");
			this.$emit('updatedValue', this.currentNode);
		},
		removePage: function () {
			this.$emit('removePage');
			console.log('removed');
		}
	},
	mounted: function () {
		this.currentNode.isSelected = true;
	}
}
</script>

<style lang="scss" scoped>
#template-page {
	display: flex;
	flex-direction: column;
	width: 100%;
	flex-wrap: nowrap;
	font-size: 1rem;
	padding: 20px;
	// overflow: scroll;
}

input {
	height: 30px;
}

.page_controls {
	display: flex;
	justify-content: space-between;
}

#buttons-template-page {
	display: flex;
	flex-direction: column;
}

#buttons-template-page button {
	width: 150px;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	display: flex;
	white-space: normal;
}

#buttons-template-page>span {
	margin: 20px;
}

div#container-moduleSelection {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 20px;
}

div#container-moduleSelection>div {
	background-color: #fff;
	border-radius: 15px;
	width: 25vw;
	height: 28vw;
	max-width: 400px;
	max-height: 450px;

	min-width: 280px;
	min-height: 220px;
	margin: 0 5vw 30px auto;
	box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.04);
	display: flex;
	align-items: center;
	flex-direction: column;
}

div#container-moduleSelection>div:last-child {
	background-color: #fff;
	border-radius: 15px;
	width: 25vw;
	height: 20vw;
	box-shadow: none;
}

div#container-moduleSelection div button {
	width: 200px;
}

.choixModules {
	padding-top: 8px;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.titreChoixModules {
	display: flex;
	width: 100%;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	height: 50px;
	padding: 8px 0;
	background: #fafafa;
	border-bottom: 1px solid #eaeaea;
}

.titreChoixModules img {
	height: 40px;
	margin-right: 15px;
	pointer-events: none;
	user-select: none;
}

.popover__wrapper {
	position: relative;
	display: inline-block;
}

.popover__content {
	opacity: 0;
	visibility: hidden;
	position: absolute;
	user-select: none;
	pointer-events: none;
	left: 15px;
	transform: translate(0, 10px);
	background-color: #f5f5f5;
	padding: 1.5rem;
	box-shadow: 0px 4px 50px 5px rgba(6, 136, 187, 0.25);
	width: auto;
	border-radius: 15px;
	// display: flex;
	flex-direction: column;
	align-items: center;
	display: none;

}

.popover__content:before {
	position: absolute;
	z-index: -1;
	content: "";
	right: calc(50% - 10px);
	top: -8px;
	border-style: solid;
	border-width: 0 10px 10px 10px;
	border-color: transparent transparent #f5f5f5 transparent;
	transition-duration: 0.3s;
	transition-property: transform;
}

// .popover__wrapper:hover .popover__content {
// 	z-index: 10;
// 	opacity: 1;
// 	visibility: visible;
// 	transform: translate(0, 0px);

// 	transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
// }
.popover__message {
	text-align: center;
}

.popover__content img {
	max-width: 80px;
}
</style>