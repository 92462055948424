<template>
	<div class="wrapper">
		<div class="templateTitre">
			<p>MODULE {{ currentNode.title }}</p>
			<el-popconfirm @confirm="removeModule" confirm-button-text='OK' cancel-button-text='Annuler' icon="el-icon-info" icon-color="red" title="Êtes-vous sûr ?">
				<el-button type="button" class="btn red" slot="reference">Supprimer ce module</el-button>
			</el-popconfirm>
		</div>

		<div v-for="(content, i) in moduleDataContent" v-bind:key="i" class="formulaire">
			<div v-for="(subContent, j) in content" v-bind:key="j">
				<div v-if="subContent.data_type === 'text'" class="text-field">
					<div class="container">
						<span>{{ subContent.libelle }}</span>
						<Input class="text-input" @input="onInputChange" placeholder="Texte" v-model="subContent.value" clearable></Input>
					</div>
				</div>
				<div v-if="['image', 'video', 'audio'].includes(subContent.data_type)" class="image-field">
					<custom-upload @updatedValue="onInputChange" :fileParams="fileUploadParams(subContent.data_type, content[j])"></custom-upload>
				</div>
				<div v-if="subContent.data_type === 'textarea'" class="textarea-field">
					<span></span>
					<div class="editor" v-if="editor">
						<menu-bar class="editor__header" :editor="editor" />
						<editor-view v-model="subContent.value" ref="editorRef" class="editor__content" :editor="editor" />
					</div>
				</div>
				<div v-if="subContent.data_type === 'checkbox'" class="checkbox-field">
					<input @input="onInputChange" v-model="subContent.value" type="checkbox" :id="`module-bouton-target-${i}`" name="checkbox_target" class="switch-input" />
					<label :for="`module-bouton-target-${i}`" class="switch-label">
						Afficher dans un nouvel onglet :
						<span class="toggle--on">Oui</span>
						<span class="toggle--off">Non</span>
					</label>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Button, Upload, Input, Popconfirm } from 'element-ui';
import { Editor } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import TextAlign from '@tiptap/extension-text-align';
import HardBreak from '@tiptap/extension-hard-break';

import UploadFile from './UploadFile.vue';
import MenuBar from './MenuBar.vue';
import EditorView from "./EditorView.vue";

export default {
	name: 'EditionModule',
	props: ['currentNode', 'siteID', 'sortedName'],
	components: {
		EditorView,
		MenuBar,
		'el-button': Button,
		Input,
		'custom-upload': UploadFile,
		'el-popconfirm': Popconfirm,

	},
	data() {
		return {

			editor: new Editor({
				editorProps: {
					handlePaste: (editor, e, slice) => {
						this.editor.commands.setContent(e.clipboardData.getData('text/plain'));
						return true;
					},
				},
				enablePasteRules: false,
				enableInputRules: false,
				extensions: [
					StarterKit.configure({
						hardBreak: false
					}),
					TextAlign.configure({
						types: ['heading', 'paragraph'],
					}),
					HardBreak.extend({
						addKeyboardShortcuts() {
							return {
								Enter: () => this.editor.commands.setHardBreak()
							}
						}
					})
				],
				onUpdate: () => {
					// HTML
					this.$nextTick(() => {
						this.onInputChange();
					});
				},
			}),
			nbEditor: 0,
			formValue: null
		};
	},
	computed: {
		moduleDataContent: function () {
			return this.currentNode.data.content;
		},

	},
	methods: {
		fileUploadParams: function (fileType, fileNode) {
			return {
				fileType: fileType,
				fileNodeValue: fileNode,
				fileBaseUrl: this.$DOCUMENTROOT_URL + '/' + this.sortedName + '/datas',
				siteID: this.siteID,
				currentNode: this.currentNode,
				deletePreviousSiteFile: this.sortedName
			}
		},
		getHTML: function () {
			// console.log('this.editor.getHTML() :>> ', this.editor.getHTML());
		},
		onInputChange: function () {
			this.$emit('updatedValue', this.currentNode);
			// console.log('this.currentNode :>> ', this.currentNode);
		},
		removeModule: function () {
			this.$emit('removeModule');
			// console.log('removed');
		},
	},
};
</script>

<style lang="scss" scoped>
.wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	flex-wrap: nowrap;
	font-size: 1rem;

	.text-input {
		margin: 5px;
	}
}

img {
	width: 100%;
	max-width: 500px;
}

textarea {
	min-width: 62vw;
	min-height: 15vh;
	margin-top: 10px;
}

.wrapper .formulaire {
	background-color: #fff;
	border-radius: 15px;
	box-shadow: 0 4px 14px 0 rgb(0 0 0 / 10%);
	padding: 15px;
	margin-bottom: 20px;
	max-width: 1100px;
}

.templateTitre {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
}

.templateImage {
	margin-top: 10px;
	margin-bottom: 10px;
	padding: 10px;
}

.text-field {
	margin: 15px;
}

.textarea-field {
	margin: 15px;

	span {
		display: block;
	}
}

input {
	height: 30px;
	margin-left: 15px;
}

.select-dropdown {
	position: relative;
	cursor: pointer;
	background-color: transparent;
	border: none;
	border-bottom: 1px solid #9e9e9e;
	outline: none;
	height: 3rem;
	line-height: 3rem;
	width: 100%;
	font-size: 16px;
	margin: 0 0 8px 0;
	padding: 0;
	display: block;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	z-index: 1;
}

.file {
	z-index: 1;
	width: 100%;
	height: 50px;
	opacity: 0;
	cursor: pointer;
	margin-top: -35px;
}

.button-upload {
	width: 150px;
	text-align: center;
	position: relative;
}

.button-upload span.label {
	position: absolute;
	z-index: 0;
	display: inline-block;
	width: 100%;
	max-width: 200px;
	background: #00bfff;
	cursor: pointer;
	color: #fff;
	padding: 10px 0;
	text-transform: uppercase;
	font-size: 12px;
	margin-top: 10px;
}

/* Switch admin */

.switch-input {
	display: none;
}

.switch-label {
	position: relative;
	display: inline-block;
	min-width: 112px;
	cursor: pointer;
	font-weight: 500;
	text-align: left;
	margin: 16px;
	padding: 16px 0 16px 44px;
}

.switch-label:before,
.switch-label:after {
	content: "";
	position: absolute;
	margin: 0;
	outline: 0;
	top: 50%;
	-ms-transform: translate(0, -50%);
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.switch-label:before {
	left: 1px;
	width: 34px;
	height: 14px;
	background-color: #9e9e9e;
	border-radius: 8px;
}

.switch-label:after {
	left: 0;
	width: 20px;
	height: 20px;
	background-color: #fafafa;
	border-radius: 50%;
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14),
		0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.084);
}

.switch-label .toggle--on {
	display: none;
}

.switch-label .toggle--off {
	display: inline-block;
}

.switch-input:checked+.switch-label:before {
	background-color: #a5d6a7;
}

.switch-input:checked+.switch-label:after {
	background-color: #4caf50;
	-ms-transform: translate(80%, -50%);
	-webkit-transform: translate(80%, -50%);
	transform: translate(80%, -50%);
}

.switch-input:checked+.switch-label .toggle--on {
	display: inline-block;
}

.switch-input:checked+.switch-label .toggle--off {
	display: none;
}

.editor {
	display: flex;
	flex-direction: column;
	max-height: 26rem;
	color: #0d0d0d;
	background-color: #fff;
	border: 1px solid #eaeaea;
	border-radius: 0.75rem;

	&__header {
		display: flex;
		align-items: center;
		flex: 0 0 auto;
		flex-wrap: wrap;
		padding: 0.25rem;
		border-bottom: 1px solid #eaeaea;
	}

	&__content {
		padding: 1.25rem 1rem;
		flex: 1 1 auto;
		overflow-x: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}
}
</style>


<style lang="scss">
/* Give a remote user a caret */
.collaboration-cursor__caret {
	position: relative;
	margin-left: -1px;
	margin-right: -1px;
	border-left: 1px solid #0d0d0d;
	border-right: 1px solid #0d0d0d;
	word-break: normal;
	pointer-events: none;
}

/* Render the username above the caret */
.collaboration-cursor__label {
	position: absolute;
	top: -1.4em;
	left: -1px;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	user-select: none;
	color: #0d0d0d;
	padding: 0.1rem 0.3rem;
	border-radius: 3px 3px 3px 0;
	white-space: nowrap;
}

/* Basic editor styles */
.ProseMirror {
	&:focus {
		outline: none;
	}

	>*+* {
		margin-top: 0.75em;
	}

	ul,
	ol {
		padding: 0 1rem;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		line-height: 1.1;
	}

	code {
		background-color: rgba(#616161, 0.1);
		color: #616161;
	}

	pre {
		background: #0d0d0d;
		color: #fff;
		font-family: "JetBrainsMono", monospace;
		padding: 0.75rem 1rem;
		border-radius: 0.5rem;

		code {
			color: inherit;
			padding: 0;
			background: none;
			font-size: 0.8rem;
		}
	}

	mark {
		background-color: #faf594;
	}

	img {
		max-width: 100%;
		height: auto;
	}

	hr {
		margin: 1rem 0;
	}

	blockquote {
		padding-left: 1rem;
		border-left: 2px solid rgba(#0d0d0d, 0.1);
	}

	hr {
		border: none;
		border-top: 2px solid rgba(#0d0d0d, 0.1);
		margin: 2rem 0;
	}

	ul[data-type="taskList"] {
		list-style: none;
		padding: 0;

		li {
			display: flex;
			align-items: center;

			>label {
				flex: 0 0 auto;
				margin-right: 0.5rem;
				user-select: none;
			}

			>div {
				flex: 1 1 auto;
			}
		}
	}
}
</style>