<template>
	<div class="wrapper">
		<div class="actions">
			<div>RACINE DU SITE</div>
			<Button @click="addPage()" type="button" class="btn blue">Ajouter une page</Button>
		</div>
		<el-input placeholder="Site titre" v-model="nouveauNomSite" clearable></el-input>
		<el-popconfirm @confirm="updateSiteName" title="Êtes vous sûr ? Cela pourrait déréférencer votre site des moteurs de recherche." confirm-button-text='OK' cancel-button-text='Annuler'>
			<el-button slot="reference">Renommer</el-button>
		</el-popconfirm>
		<div class="description-portail">
			<h2>Description affichée sur le portail des expositions</h2>
			<div>
				<span>Nom du musée :</span>
				<el-input @input="onInputChange" placeholder="Titre" v-model="currentNode.data.content.title_museum" clearable></el-input>
			</div>
			<div>
				<span>Nom de l'exposition :</span>
				<el-input @input="onInputChange" placeholder="Please input" v-model="currentNode.data.content.title_expo" clearable></el-input>
			</div>
			<div>
				<span>Description :</span>
				<el-input @input="onInputChange" placeholder="Please input" v-model="currentNode.data.content.description" maxlength="90" show-word-limit clearable></el-input>
			</div>
			<custom-upload fileType="image" :fileParams="fileUploadParams" @updatedValue="onInputChange"></custom-upload>
		</div>
	</div>
</template>

<script>
import UploadFile from './UploadFile.vue';
import { Button, Upload, Input, Checkbox, Popconfirm, } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
const axios = require('axios');


export default {
	name: 'EditionModule',
	props: ['currentNode', 'siteID', 'sortedName'],
	components: {
		'el-button': Button,
		'el-popconfirm': Popconfirm,
		Upload,
		'el-input':Input,
		'el-checkbox': Checkbox,
		'custom-upload': UploadFile
	},
	data() {
		return {
			nouveauNomSite: this.currentNode.title,
			isUploaded: false,
			fileUploadParams: {
				fileType: "image",
				fileNodeValue: this.currentNode.data.content.image,
				fileBaseUrl: this.$DOCUMENTROOT_URL + '/' + this.sortedName + '/datas',
				siteID: this.currentNode.data.id,
				currentNode: this.currentNode,
				deletePreviousSiteFile: this.sortedName
			},
		};
	},
	// created() {
	// 	console.log('this.currentNode :>> ', this.currentNode);
	// },
	methods: {
		addPage: function () {
			this.$emit('addPage');
		},
		onInputChange: function () {
			this.$emit('updatedValue', this.currentNode);
		},
		updateSiteName: function (e) {
			const loading = this.$loading({
				lock: true,
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			axios
				.put(`${this.$API_URL}/sites/${this.siteID}/name`, { jsonFile: this.currentNode, newName: this.nouveauNomSite },
					{ headers: { 'content-type': 'application/json' } }
				)
				.then((response) => {
					this.$router.push('/sites')
					this.$notify({
						title: 'Succès',
						message: 'Le site a bien été renommé.',
						type: 'success'
					});
					setTimeout(() => {
						loading.close();
					}, 100);
				})
				.catch((error) => {
					const { response } = error;
					const { request, ...errorObject } = response; // take everything but 'request'

					if (errorObject.status === 409) {
						this.$message({
							type: 'error',
							message: 'Le site existe déjà !'
						})
					} else if (errorObject.status === 422) {
						this.$message({
							type: 'warning',
							message: 'Le site est utilisé dans le portail. Il faut re-publier le site et le portail afin de récréer les bons chemins.'
						})
					} else {
						this.$message({
							type: 'warning',
							message: 'Erreur lors de la modification...'
						})
					}
					loading.close();
				})

		}
	},
};
</script>

<style lang="scss" scoped>
.wrapper {
	padding: 20px;
}

.el-upload__text {
	position: relative;
}

.el-icon-upload {
	position: relative;
}

.uploaded-image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	opacity: 0.5;
}
</style>
