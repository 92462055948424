<template>
	<div @click="contextMenuIsVisible = false" id="edit-site">
		<div id="editionHeader">
			<span class="filAriane">
				<router-link class="afficherListe" to="/sites">Tous les sites</router-link>/
				<span v-if="!isFetching" id="filAriane-nomSite">{{ site[0].title }}</span>
			</span>
			<span id="outputButtons">
				<span class="etat"></span>
				<button @click="publishSite()" class="btn green" type="button">Publier</button>
				<a :href="urlToSite" target="_blank" class="editionVoir btn blue" type="button">Voir la page actuelle</a>
			</span>
		</div>
		<main v-if="!isFetching" id="edition-main">
			<sl-vue-tree ref="slVueTree" :allow-multiselect="false" @select="nodeSelected" @drop="nodeDropped" @input="onDragHandler" @nodecontextmenu="showContextMenu" id="arbre-site" v-model="site">
				<template slot="title" slot-scope="{ node }">
					<span class="item-icon">

						<i class="fa el-icon-house" v-if="!node.isLeaf && node.data.type === 'root'"></i>
						<span class="el-icon-tickets" v-if="!node.isLeaf && node.data.type === 'page'"></span>
						<img class="el-icon-module" v-if="node.isLeaf" :src="moduleIcon" alt="module icon">

					</span>
					{{ node.title }}
				</template>
			</sl-vue-tree>

			<AjouterPage @addPage="addPageHandler" v-if="currentNode.data.type === 'root'" @updatedValue="updatedValueHandler" :currentNode="currentNode" :sortedName="sortedName" :siteID="id" id="ajoutPage"></AjouterPage>
			<AjouterModule :currentNode="currentNode" @removePage="removeNode" @addModule="addModuleHandler" @updatedValue="updatedPageNameHandler" v-else-if="currentNode.data.type === 'page'"></AjouterModule>
			<EditionModule v-else @removeModule="removeNode" :sortedName="sortedName" @updatedValue="updatedValueHandler" :currentNode="currentNode" :siteID="id" id="edition"></EditionModule>
		</main>
		<div class="contextmenu" ref="contextmenu" v-show="contextMenuIsVisible">
			<div @click="removeNode">Remove</div>
		</div>
	</div>
</template>

<script>
const axios = require('axios');

import AjouterModule from '../components/AjouterModule.vue';
import AjouterPage from '../components/AjouterPage.vue';
import SlVueTree from 'sl-vue-tree';
import 'sl-vue-tree/dist/sl-vue-tree-minimal.css';
import EditionModule from '../components/EditionModule.vue';

export default {
	name: 'EditionSite',
	components: {
		SlVueTree,
		EditionModule,
		AjouterModule,
		AjouterPage,
	},
	props: ['sortedName'],
	data: function () {
		return {
			site: [],
			id: '',
			isFetching: true,
			currentNode: undefined,
			saveTimeOut: undefined,
			page: '',
			prevNodes: [],
			contextMenuIsVisible: false,
			nodeModules: {
				TitleModule: {
					title: 'Titre',
					isLeaf: true,
					data: {
						type: 'title',
						content: [
							[
								{
									data_type: 'text',
									libelle: 'Titre',
									value: 'Ajouter votre texte',
								},
							],
						],
					},
				},
				BandeauImageModule: {
					title: 'Bandeau image & titre',
					isLeaf: true,
					data: {
						type: 'bandeauImage',
						content: [
							[
								{
									data_type: 'text',
									libelle: 'Titre',
									value: '',
								},
								{
									data_type: 'text',
									libelle: 'Sous-Titre',
									value: '',
								},
								{
									data_type: 'text',
									libelle: 'Legende',
									value: 'placeholder legende',
								},
								{
									data_type: 'image',
									url: '',
									name: ''
								},
							],
						],
					},
				},
				CarouselModule: {
					title: "Carousel d'accueil",
					isLeaf: true,
					data: {
						type: 'carousel',
						content: [
							[
								{
									data_type: 'text',
									libelle: 'Titre',
									value: '',
								},
								{
									data_type: 'text',
									libelle: 'Sous-Titre',
									value: '',
								},
								{
									data_type: 'text',
									libelle: 'Encart',
									value: '',
								}

							],
							[
								{
									data_type: 'image',
									url: '',
									name: ''
								},
								{
									data_type: 'image',
									url: '',
									name: ''
								},
								{
									data_type: 'image',
									url: '',
									name: ''
								},
								{
									data_type: 'image',
									url: '',
									name: ''
								},
								{
									data_type: 'image',
									url: '',
									name: ''
								},
								{
									data_type: 'image',
									url: '',
									name: ''
								},
								{
									data_type: 'image',
									url: '',
									name: ''
								}
							]
						],
					},
				},
				ImageModule: {
					title: 'Une image',
					isLeaf: true,
					data: {
						type: 'imageUnique',
						content: [
							[
								{
									data_type: 'textarea',
									libelle: 'Légende',
									value: 'Ajouter votre legende',
								},
								{
									data_type: 'image',
									url: '',
									name: ''
								},
							],
						],
					},
				},
				DoubleImageModule: {
					title: 'Deux images',
					isLeaf: true,
					data: {
						type: 'imageDouble',
						content: [
							[
								{
									data_type: 'text',
									libelle: 'Artiste',
									class: 'italic',
									value: 'Ajouter votre legende',
								},
								{
									data_type: 'text',
									libelle: 'Titre',
									class: 'bold',

									value: 'Ajouter votre legende',
								},
								{
									data_type: 'text',
									libelle: 'Date/Copyright',
									class: '',
									value: 'Ajouter votre legende',
								},
								{
									data_type: 'image',
									url: '',
									name: ''
								},
							],
							[
								{
									data_type: 'text',
									libelle: 'Artiste',
									class: 'italic',
									value: 'Ajouter votre legende',
								},
								{
									data_type: 'text',
									libelle: 'Titre',
									value: 'Ajouter votre legende',
									class: 'bold',
								},
								{
									data_type: 'text',
									libelle: 'Date/Copyright',
									class: '',
									value: 'Ajouter votre legende',
								},
								{
									data_type: 'image',
									url: '',
									name: ''
								},
							],
						],
					},
				},
				ImageParagraphModule: {
					title: 'Image & paragraphe',
					isLeaf: true,
					data: {
						type: 'imageParagraph',
						content: [
							[
								{
									data_type: 'textarea',
									libelle: 'Texte colonne',
									value: '',
								},
								{
									data_type: 'image',
									url: '',
									name: ''
								},
							],
						],
					},
				},
				TextareaModule: {
					title: 'Paragraphe',
					isLeaf: true,
					data: {
						type: 'textarea',
						content: [
							[
								{
									data_type: 'textarea',
									libelle: 'Texte colonne',
									value: '',
								},
							],
						],
					},
				},
				ButtonImageModule: {
					title: 'Boutons liens avec images',
					isLeaf: true,
					data: {
						type: 'linkButtonImages',
						content: [
							[
								{
									data_type: 'text',
									libelle: 'Texte du bouton',
									value: '',
								},
								{
									data_type: 'text',
									libelle: 'Lien (sans https://)',
									value: 'Ajouter votre lien',
								},
								{
									data_type: 'checkbox',
									value: '',
								},
								{
									data_type: 'image',
									url: '',
									name: ''
								},
							],
							[
								{
									data_type: 'text',
									libelle: 'Texte du bouton',
									value: '',
								},
								{
									data_type: 'text',
									libelle: 'Lien (sans https://)',
									value: 'Ajouter votre lien',
								},
								{
									data_type: 'checkbox',
									value: '',
								},
								{
									data_type: 'image',
									url: '',
									name: ''
								},
							],
							[
								{
									data_type: 'text',
									libelle: 'Texte du bouton',
									value: '',
								},
								{
									data_type: 'text',
									libelle: 'Lien (sans https://)',
									value: 'Ajouter votre lien',
								},
								{
									data_type: 'checkbox',
									value: '',
								},
								{
									data_type: 'image',
									url: '',
									name: ''
								},
							],
						],
					},
				},
				DoubleTextareaModule: {
					title: 'Paragraphe deux colonnes',
					isLeaf: true,
					data: {
						type: 'doubleTextarea',
						content: [
							[
								{
									data_type: 'textarea',
									libelle: 'Texte colonne 1',
									value: '',
								},
							]
						],
					},
				},
				ButtonModule: {
					title: 'Bouton lien',
					isLeaf: true,
					data: {
						type: 'linkButton',
						content: [
							[
								{
									data_type: 'text',
									libelle: 'Texte du bouton',
									value: '',
								},
								{
									data_type: 'text',
									libelle: 'Lien (sans https://)',
									value: 'Ajouter votre lien',
								},
								{
									data_type: 'checkbox',
									value: '',
								},
							],
						],
					},
				},
				GalerieModule: {
					title: 'Galerie',
					isLeaf: true,
					data: {
						type: 'galerie',
						content: [
							[
								{
									data_type: 'text',
									libelle: 'Texte 1',
									value: '',
								},
								{
									data_type: 'text',
									libelle: 'Texte 2',
									value: '',
								},
								{
									data_type: 'text',
									libelle: 'Texte 3',
									value: '',
								},
								{
									data_type: 'image',
									url: '',
									name: ''
								},
							],
							[
								{
									data_type: 'text',
									libelle: 'Texte 1',
									value: '',
								},
								{
									data_type: 'text',
									libelle: 'Texte 2',
									value: '',
								},
								{
									data_type: 'text',
									libelle: 'Texte 3',
									value: '',
								},
								{
									data_type: 'image',
									url: '',
									name: ''
								},
							],
							[
								{
									data_type: 'text',
									libelle: 'Texte 1',
									value: '',
								},
								{
									data_type: 'text',
									libelle: 'Texte 2',
									value: '',
								},
								{
									data_type: 'text',
									libelle: 'Texte 3',
									value: '',
								},
								{
									data_type: 'image',
									url: '',
									name: ''
								},
							],
							[
								{
									data_type: 'text',
									libelle: 'Texte 1',
									value: '',
								},
								{
									data_type: 'text',
									libelle: 'Texte 2',
									value: '',
								},
								{
									data_type: 'text',
									libelle: 'Texte 3',
									value: '',
								},
								{
									data_type: 'image',
									url: '',
									name: ''
								},
							],
							[
								{
									data_type: 'text',
									libelle: 'Texte 1',
									value: '',
								},
								{
									data_type: 'text',
									libelle: 'Texte 2',
									value: '',
								},
								{
									data_type: 'text',
									libelle: 'Texte 3',
									value: '',
								},
								{
									data_type: 'image',
									url: '',
									name: ''
								},
							],
							[
								{
									data_type: 'text',
									libelle: 'Texte 1',
									value: '',
								},
								{
									data_type: 'text',
									libelle: 'Texte 2',
									value: '',
								},
								{
									data_type: 'text',
									libelle: 'Texte 3',
									value: '',
								},
								{
									data_type: 'image',
									url: '',
									name: ''
								},
							],
							[
								{
									data_type: 'text',
									libelle: 'Texte 1',
									value: '',
								},
								{
									data_type: 'text',
									libelle: 'Texte 2',
									value: '',
								},
								{
									data_type: 'text',
									libelle: 'Texte 3',
									value: '',
								},
								{
									data_type: 'image',
									url: '',
									name: ''
								},
							],
							[
								{
									data_type: 'text',
									libelle: 'Texte 1',
									value: '',
								},
								{
									data_type: 'text',
									libelle: 'Texte 2',
									value: '',
								},
								{
									data_type: 'text',
									libelle: 'Texte 3',
									value: '',
								},
								{
									data_type: 'image',
									url: '',
									name: ''
								},
							]
						]
					}
				},
				VideoModule: {
					title: 'Vidéo',
					isLeaf: true,
					data: {
						type: "video",
						content: [
							[
								{
									data_type: 'text',
									libelle: 'Titre',
									value: '',
								},
								{
									data_type: 'text',
									libelle: 'Date/Source',
									value: '',
								},
								{
									data_type: 'text',
									libelle: 'Description',
									value: '',
								},
								{
									data_type: 'video',
									value: 'default',
								},
							]
						]
					}
				},
				AudioModule: {
					title: 'Audio',
					isLeaf: true,
					data: {
						type: "audio",
						content: [
							[
								{
									data_type: 'text',
									libelle: 'Titre',
									value: '',
								},
								{
									data_type: 'text',
									libelle: 'Date/Source',
									value: '',
								},
								{
									data_type: 'text',
									libelle: 'Description',
									value: '',
								},
								{
									data_type: 'audio',
									value: 'default',
								},
							]
						]
					}
				},
				YoutubeModule: {
					title: 'Vidéo youtube',
					isLeaf: true,
					data: {
						type: 'youtube',
						content: [
							[
								{
									data_type: 'text',
									libelle: 'Lien youtube (ID de la vidéo)',
									value: 'ID vidéo',
								},
							],
						],
					},
				},

			},
			moduleIcon: require('../assets/module-icon.svg')
		};
	},
	computed: {
		urlToSite: function () {
			return `${this.$DOCUMENTROOT_URL}/${this.sortedName}/${this.page}`;
		},
	},
	methods: {
		nodeSelected(nodes, event) {
			this.currentNode = nodes[0];
			this.prevNodes = this.site;

			if (this.currentNode.data.type === "root") {
				this.page = '';
			} else if (this.currentNode.data.type === "page") {
				JSON.stringify(this.currentNode.path) === "[0,0]" ? this.page = '' : this.page = this.currentNode.data.sortedName + '.html';
			} else {
				let path = this.cloneDeep(this.currentNode.path);
				path.pop();

				this.site[0].children.filter((child, index) => {
					if (JSON.stringify(child.path) === JSON.stringify(path)) {
						JSON.stringify(child.path) === "[0,0]" ? this.page = '' : this.page = child.data.sortedName + '.html';
					}
				});
			}
			// console.log('this.currentNode :>> ', JSON.stringify(this.currentNode, null, 2));
		},
		nodeDropped(nodes, position, event) {

			// Prevent from moving the node to undesired position
			if ((nodes[0].data.type === "page" && !((position.node.data.type == "page" && position.placement != "inside") || position.node.data.type == "root")) || (nodes[0].data.type !== "page" && (position.node.data.type === "page" && position.placement !== "inside") || position.node.data.type === "root")) {
				this.site = this.cloneDeep(this.prevNodes);
				this.$message({
					type: 'warning',
					message: 'L\'élément n\'est pas déplaçable à cet endroit.'
				});
				return;
			}

			const $slVueTree = this.$refs.slVueTree;
			this.currentNode = $slVueTree.getSelected()[0];

		},
		onDragHandler(newNode) {
			if (newNode.length === 2) {
				this.site = this.cloneDeep(this.prevNodes);
				this.$message({
					type: 'warning',
					message: 'Un seul élément doit être présent à la base du site.'
				});
				return;
			}
		},
		cloneDeep(obj) {
			return JSON.parse(JSON.stringify(obj))
		},
		showContextMenu(node, event) {
			event.preventDefault();
			this.contextMenuIsVisible = true;
			const $contextMenu = this.$refs.contextmenu;
			$contextMenu.style.left = event.clientX + 'px';
			$contextMenu.style.top = event.clientY + 'px';
		},
		async removeNode() {
			const loading = this.$loading({
				lock: true,
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});

			this.contextMenuIsVisible = false;
			const $slVueTree = this.$refs.slVueTree;
			this.currentNode = $slVueTree.nodes[0];
			let getSelected = $slVueTree.getSelected()
			const paths = getSelected.map((node) => node.path);

			let imagesToDelete = [];

			if (getSelected[0].data.type === "page") {
				getSelected[0].children.forEach(module => {
					module.data.content.forEach(fieldsGroup => {
						fieldsGroup.forEach(element => {
							if (element.data_type === "image" && element.url)
								imagesToDelete.push(element);
						});
					})
				})
			} else {
				getSelected[0].data.content.forEach(module => {
					module.forEach(element => {
						if (element.data_type === "image" && element.url)
							imagesToDelete.push(element);
					});
				});
			}

			console.log('imagesToDelete :>> ', imagesToDelete);

			await axios
				.delete(`${this.$API_URL}/sites/${this.id}/image`, { data: { images: imagesToDelete } })
				.then(function (response) {
					// handle success
					console.log('image supp')
				})
				.catch(function (error) {
					// handle error
					console.log('image pas supp')
					console.log(error);
				})

			$slVueTree.remove(paths);

			this.saveSite();
			setTimeout(() => {
				loading.close();
			}, 100);
		},
		addPageHandler() {
			const $slVueTree = this.$refs.slVueTree;
			let childrenLength = $slVueTree.getNextNode(0).children.length;
			let nodeInsert = $slVueTree.getNextNode(0).children[childrenLength - 1];
			if (childrenLength === 0) {
				nodeInsert = $slVueTree.getLastNode();
				$slVueTree.insert(
					{ node: nodeInsert, placement: 'inside' },
					{ title: 'Page', isLeaf: false, data: { type: 'page', sortedName: 'page' }, children: [] }
				);
			} else {
				$slVueTree.insert({ node: nodeInsert, placement: 'after', }, { title: 'Page', sortedName: 'page', isLeaf: false, data: { type: 'page' }, children: [] });
			}
		},
		addModuleHandler(value) {
			const $slVueTree = this.$refs.slVueTree;
			const paths = $slVueTree.getSelected();
			let childrenLength = paths[0].children.length;
			if (childrenLength === 0) {
				$slVueTree.insert({ node: paths[0], placement: 'inside' }, this.nodeModules[value]);
			} else {
				$slVueTree.insert({ node: paths[0].children[childrenLength - 1], placement: 'after' }, this.nodeModules[value]);
			}
		},
		saveSite() {
			const $slVueTree = this.$refs.slVueTree;
			// let json = $slVueTree.nodes;
			let json = this.cloneDeep($slVueTree.nodes);

			// Page selection management
			// We need to remove the selected node value from the json
			// and set the selected node value to the first node (root)
			json[0].isSelected = true;
			json[0].children.forEach(page => {
				page.isSelected = false;
				page.children.forEach(module => { module.isSelected = false })
			});

			axios
				.put(`${this.$API_URL}/sites/${this.id}`, { jsonFile: json },
					{ headers: { 'content-type': 'application/json' } }
				)
				.then(function (response) {
					// console.log('saving>> ', $slVueTree.nodes);

				})
		},
		publishSite() {
			axios
				.post(`${this.$API_URL}/sites/${this.id}/publish`)
				.then((response) => {
					// console.log('response :>> ', response);
					this.$notify({
						title: 'Succès',
						message: 'Le site a bien été publié ! 🎉',
						type: 'success'
					});

				})
				.catch((error) => {
					// handle error
					console.log(error);
					this.$notify.error({
						title: 'Error',
						message: 'Une erreur inattendue s\'est produite ! 😥'
					});
				})
		},
		updatedValueHandler(data) {
			const $slVueTree = this.$refs.slVueTree;
			$slVueTree.updateNode(data.path ? data.path : [0], data);

			// Debouncing input
			clearTimeout(this.saveTimeOut);
			this.saveTimeOut = setTimeout(() => {
				this.saveSite();
			}, 500);
		},
		updatedPageNameHandler(data) {
			const $slVueTree = this.$refs.slVueTree;
			$slVueTree.updateNode(data.path, data);
			clearTimeout(this.saveTimeOut);
			this.saveTimeOut = setTimeout(() => {
				this.saveSite();
			}, 500);
		},
		showTree() {
			console.log('this.site :>> ', JSON.stringify(this.site, null, 2));
		},
	},
	created() {
		axios
			.get(`${this.$API_URL}/sites/${this.sortedName}`)
			.then((response) => {
				let array = [response.data];
				this.site = array;
				this.id = response.data.data.id;

				this.prevNodes = this.cloneDeep(this.site)
				this.currentNode = this.site[0];
				this.isFetching = false;


			})
			.catch(function (error) {
				// handle error
				console.log(error);
			})

	},


};
</script>

<style lang="scss" scoped>


#edit-site {
	height: calc(100% - 120px);
}
div#editionHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	height: 60px;
	background: #eee;
	/*rgba(0, 0, 0, .3);*/
}

#outputButtons {
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin-right: 30px;
}

span.filAriane {
	display: inline-block;
	padding: 20px;
	font-size: 20px;
}

span.filAriane>.afficherListe {
	color: #000;
	text-decoration: none;
	font-weight: 600;
}

#edition-main {
	display: flex;
	flex: 1;


	#arbre-site {
		flex: 0 0 300px;
		background: #eee;
		
	}
}

#edition {
	flex: 1;
	padding: 20px;
	height: calc(100vh - 120px);
	background: #fff;
	display: flex;
	overflow: scroll;
}

.contextmenu {
	position: absolute;
	background-color: white;
	color: black;
	border-radius: 2px;
	cursor: pointer;
	top: 0;
}

.contextmenu>div {
	padding: 10px;
}

.contextmenu>div:hover {
	background-color: rgba(100, 100, 255, 0.5);
}

.el-icon-module {
	transform: translateY(3px);
}
</style>
